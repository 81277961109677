'use client'

import { modelConfigs } from 'app/api/types/types'
import { ModelSelector } from 'components/modelSelector'
import { useAtom } from 'jotai'
import {
  currentPrompt as currentPromptAtom,
  selectedModels as selectedModelsAtom,
} from 'store/promptStore'
import { locationAtom } from 'store/fileStore'
import { useEffect, useState } from 'react'
import { Button } from '@headlessui/react'

export default function QuickPrompt() {
  const [currentPrompt, setCurrentPrompt] = useAtom(currentPromptAtom)
  const [selectedModels, setSelectedModels] = useAtom(selectedModelsAtom)
  const [location, setLocation] = useAtom(locationAtom)
  const [selectedSubject, setSelectedSubject] = useState<string>('Anything') // Default to 'Anything'

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault()
    if (currentPrompt.trim()) {
      const searchParams = new URLSearchParams({
        prompt: currentPrompt,
        models: selectedModels.map(m => m.name).join(','),
        subject: selectedSubject,
      })
      setLocation({ pathname: 'prompt', searchParams })
    }
  }

  useEffect(() => {
    location.searchParams?.set('subject', selectedSubject)
    setLocation(location)
  }, [selectedSubject])

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (
      e.key === 'Enter' &&
      !e.shiftKey &&
      currentPrompt.trim() &&
      selectedModels.length > 0
    ) {
      handleSearch(e)
    }
  }

  return (
    <div className='flex flex-col items-center justify-center gap-4 p-10 size-full overflow-hidden'>
      <div className='w-full max-w-full md:max-w-lg'>
        <form onSubmit={handleSearch} className='flex flex-col gap-2 relative'>
          <div className='flex items-center gap-2'>
            <span className='text-white text-sm md:text-base lg:text-xl'>
              I want to learn...
            </span>
            <select
              value={selectedSubject}
              onChange={e => setSelectedSubject(e.target.value)}
              className='p-2 rounded-lg border-b-2 border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 bg-transparent text-white text-sm md:text-base lg:text-lg'
            >
              <option value='Anything'>Anything</option>
              <option value='Automation Tutorial'>Automation Tutorial</option>
            </select>
          </div>
          <div className='relative   rounded-lg   w-full flex items-stretch'>
            <textarea
              rows={3}
              value={currentPrompt}
              onChange={e => setCurrentPrompt(e.target.value)}
              onKeyDown={handleKeyDown}
              className='w-full p-2 pr-20  focus:outline-none focus:ring-2 focus:ring-blue-500 bg-neutral-700 text-white text-sm md:text-base lg:text-lg resize-none overflow-hidden'
              style={{ maxHeight: '9rem' }} // 6 lines * 1.5rem line height
              placeholder='Enter your prompt...'
            />
            <Button
              onClick={handleSearch}
              className='bg-blue-500 hover:bg-blue-700 text-white  h-auto pl-4 pr-4'
            >
              Go
            </Button>
          </div>
        </form>
      </div>
      <div className='w-full max-w-full md:max-w-lg'>
        <ModelSelector
          selectedModels={selectedModels}
          setSelectedModels={setSelectedModels}
          modelConfigs={modelConfigs}
        />
      </div>
    </div>
  )
}
