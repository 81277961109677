// apiClient.ts
import {
  ChatRequest,
  CliChatResponse,
  PromptChatResponse,
} from 'app/api/types/types'

const API_URL = '/api/prompt'

async function fetchChatResponse<T>(request: ChatRequest): Promise<T> {
  const response = await fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  })

  if (!response.ok) {
    throw new Error('Failed to fetch chat response')
  }

  return await response.json()
}

export async function fetchPromptChatResponse(
  request: ChatRequest,
): Promise<PromptChatResponse> {
  return fetchChatResponse<PromptChatResponse>(request)
}

export async function fetchCliChatResponse(
  request: ChatRequest,
): Promise<CliChatResponse> {
  return fetchChatResponse<CliChatResponse>(request)
}

export async function fetchFilenameChatResponse(
  request: ChatRequest & { type: 'filename' },
): Promise<{ fileName: string }> {
  return fetchChatResponse<{ fileName: string }>(request)
}
