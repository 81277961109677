'use client'

import { useState } from 'react'
import { Dialog, DialogBackdrop } from '@headlessui/react'
import { Button } from '@headlessui/react'
import {
  CheckIcon,
  Combobox,
  Group,
  Pill,
  PillsInput,
  useCombobox,
} from '@mantine/core'
import { useAtomValue } from 'jotai'
import Image from 'next/image'
import { locationAtom } from 'store/fileStore'

export function MultiSelectCreatable(props: {
  data: string[]
  placeholder: string
  onChange: (value: string[]) => void
  initialSelected?: string[]
}) {
  const [search, setSearch] = useState('')
  const [data, setData] = useState(props.data)
  const [value, setValue] = useState<string[]>(props.initialSelected || [])
  const location = useAtomValue(locationAtom)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCopied, setIsCopied] = useState(false)

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption()
      setIsCopied(false)
    },
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  })

  const handleValueSelect = (val: string) => {
    setSearch('')

    if (val === '$create') {
      const newVal = [...data, search]
      setData(newVal)
      setValue(newVal)
      props.onChange(newVal)
    } else {
      const newVal = value.includes(val)
        ? value.filter(v => v !== val)
        : [...value, val]
      setValue(newVal)
      props.onChange(newVal)
    }
  }

  const handleValueRemove = (val: string) =>
    setValue(current => current.filter(v => v !== val))

  const values = value.map(item => (
    <Pill key={item} withRemoveButton onRemove={() => handleValueRemove(item)}>
      {item}
    </Pill>
  ))

  const options = data
    .filter(item => item.toLowerCase().includes(search.trim().toLowerCase()))
    .map(item => (
      <Combobox.Option value={item} key={item} active={value.includes(item)}>
        <Group gap='sm'>
          {value.includes(item) ? <CheckIcon size={12} /> : null}
          <span>{item}</span>
        </Group>
      </Combobox.Option>
    ))

  const handleAddAISearch = () => {
    const promptUrl =
      window.location.origin +
      '/prompt?' +
      location.searchParams?.toString() +
      '&prompt=%s'
    navigator.clipboard.writeText(promptUrl)
    setIsCopied(true)
  }

  return (
    <>
      <Combobox
        store={combobox}
        onOptionSubmit={handleValueSelect}
        withinPortal={false}
      >
        <Combobox.DropdownTarget>
          <PillsInput onClick={() => combobox.openDropdown()}>
            <Pill.Group>
              {values}

              <Combobox.EventsTarget>
                <PillsInput.Field
                  onFocus={() => combobox.openDropdown()}
                  onBlur={() => combobox.closeDropdown()}
                  value={search}
                  placeholder={props.placeholder}
                  onChange={event => {
                    combobox.updateSelectedOptionIndex()
                    setSearch(event.currentTarget.value)
                  }}
                  onKeyDown={event => {
                    if (event.key === 'Backspace' && search.length === 0) {
                      event.preventDefault()
                      handleValueRemove(value[value.length - 1])
                    }
                  }}
                />
              </Combobox.EventsTarget>
            </Pill.Group>
          </PillsInput>
        </Combobox.DropdownTarget>

        <Combobox.Dropdown>
          <Combobox.Options>{options}</Combobox.Options>
          {value.length > 0 && (
            <Combobox.Footer>
              <Button
                className='bg-neutral-800 hover:bg-neutral-700 pl-2 pr-2 rounded'
                onClick={handleAddAISearch}
              >
                {isCopied ? 'Copied!' : 'Copy AI Search URL'}
              </Button>
              <Button
                className='ml-2 bg-neutral-800 hover:bg-neutral-700 pl-2 pr-2 rounded'
                onClick={() => setIsModalOpen(true)}
              >
                ?
              </Button>
            </Combobox.Footer>
          )}
        </Combobox.Dropdown>
      </Combobox>

      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className='fixed inset-0 z-10 overflow-y-auto'
      >
        <div className='flex items-center justify-center min-h-screen'>
          <DialogBackdrop className='fixed inset-0 bg-black opacity-30' />
          <div className='relative bg-neutral-950 rounded mx-auto p-4'>
            <Dialog.Title className='text-lg font-bold'>
              Add a Custom Search Engine
            </Dialog.Title>
            <Dialog.Description className='mt-2'>
              <p>Step 1: Click &quot;Copy AI Search URL&quot; button</p>
              <p>
                Step 2: Click &quot;Navigate to
                chrome://settings/searchEngines&quot; button
              </p>
              <p>Step 3: Click &quot;Add New&quot; and fill out the fields</p>
              <p>
                Step 4: Typing the prefix into the browser will now let you
                search anything with AI
              </p>
              <Image
                src='/addSearchEngine.gif'
                alt='Add Search Engine'
                width={1200}
                height={900}
                className='mt-4'
              />
            </Dialog.Description>
            <Button
              className='mt-4 bg-neutral-800 hover:bg-neutral-700 pl-2 pr-2 rounded'
              onClick={() => setIsModalOpen(false)}
            >
              Close
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export function ModelSelector({
  selectedModels,
  setSelectedModels,
  modelConfigs,
}: {
  selectedModels: any[]
  setSelectedModels: any
  modelConfigs: any[]
}) {
  const [selected, setSelected] = useState(
    selectedModels.map(model => model.uiName),
  )

  const handleChange = (value: string[]) => {
    setSelected(value)
    setSelectedModels(modelConfigs.filter(m => value.includes(m.uiName)))
  }

  return (
    <MultiSelectCreatable
      placeholder='Select Models'
      data={modelConfigs.map(model => model.uiName)}
      onChange={handleChange}
      initialSelected={selected}
    />
  )
}
