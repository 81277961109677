import { atom } from 'jotai'
import {
  fetchFilenameChatResponse,
  fetchPromptChatResponse,
} from 'service/promptService'
import { ChatRequest, modelConfigs } from 'app/api/types/types'
import { AIFile, LocalFile, PromptWithId } from 'app/api/fileService'

// define the array first, note the const
import { ModelConfig } from 'app/api/types/types'
import {
  addOpenPaths,
  currentFile,
  isLoadingPrompt,
  locationAtom,
} from 'store/fileStore'
import { atomEffect } from 'jotai-effect'
import { v4 as uuidv4 } from 'uuid'
import { atomWithStorage } from 'jotai/utils'

export const selectedModels = atom<ModelConfig[]>(
  modelConfigs.filter(m => m.uiName == 'Claude 3.5 Haiku'),
)

export const currentPrompt = atom('')

const type = atom<ChatRequest['type']>('Automation Tutorial')

export const promptIds = atomWithStorage<PromptWithId[]>(
  'promptIds',
  [],
  undefined,
  { getOnInit: true },
)

export const runPrompt = atom(null, async (get, set) => {
  const _currentPrompt = get(currentPrompt)
  const _selectedModels = get(selectedModels)
  const _type = get(type)

  if (!_currentPrompt || _selectedModels.length === 0) {
    return
  }
  set(isLoadingPrompt, true)
  try {
    const request: ChatRequest = {
      type: _type,
      prompt: _currentPrompt,
      systemPrompt: '',
      selectedModels: [],
      widgetTypes: [],
      fileName: '',
    }

    const filenameRequest: ChatRequest & { type: 'filename' } = {
      ...request,
      type: 'filename',
    }

    const filenameResponse = await fetchFilenameChatResponse(filenameRequest)
    request.fileName = filenameResponse.fileName

    const promptId = uuidv4() // Generate a UUID using the v4 function
    set(promptIds, ids => [...ids, { prompt: request.prompt, promptId }])
    _selectedModels.forEach(async model => {
      let firstRequestReturned = false
      try {
        const response = await fetchPromptChatResponse({
          ...request,
          selectedModels: [model.name],
        })

        if (response.responses.length > 0) {
          const file: AIFile = {
            type: 'ai',
            uiName: model.uiName,
            path: response.responses[0].fileName,
            saved: false,
            content: response.responses[0].response,
            promptId,
          }
          set(addOpenPaths, [file])
          if (get(isLoadingPrompt)) {
            set(currentFile, file)
            firstRequestReturned = true
            set(isLoadingPrompt, false)
          }
        }
      } catch (e) {
        console.error(e)
      }
    })
  } catch (error) {
    console.error('error fetching chat response:', error)
    set(isLoadingPrompt, false)
  }
})

export const runPromptForLocation = atomEffect((get, set) => {
  const currentLocation = get(locationAtom)
  let path = currentLocation.pathname
  if (path && path.startsWith('/')) {
    path = path.slice(1)
  }
  const searchParams = currentLocation.searchParams
  if (path == 'prompt' && searchParams) {
    const prompt = searchParams.get('prompt')
    const models = searchParams.getAll('models')
    const subject = searchParams.get('subject')
    if (!prompt || models.length === 0) {
      return
    }
    set(
      selectedModels,
      modelConfigs.filter(m => models.includes(m.name)),
    )
    // @ts-ignore
    subject && set(type, subject)
    set(currentPrompt, prompt)
    set(runPrompt)
  }
})

export const updateModelsInLocationEffect = atomEffect((get, set) => {
  const models = get(selectedModels)
  const modelNames = models.map(m => m.name)
  const currentLocation = get(locationAtom)
  const searchParams = new URLSearchParams(currentLocation.searchParams)

  // Update the models search parameter
  const currentSearchParamModels = searchParams.getAll('models')
  const m = models.find(m => !currentSearchParamModels.includes(m.name))
  const m2 = currentSearchParamModels.find(m => !modelNames.includes(m))
  if (!m && !m2) {
    return
  }
  searchParams.delete('models')
  modelNames.forEach(m => searchParams.append('models', m))
  // Set the updated locationAtom
  set(locationAtom, {
    ...currentLocation,
    searchParams,
  })
})
