import {
  File,
  GithubFile,
  githubFileService,
  isFileAnonymous,
  isFilePathAI,
  LocalFile,
  localFileService,
  s3FileService,
} from 'app/api/fileService'
import { modelConfigs } from 'app/api/types/types'

import { atom } from 'jotai'
import { atomWithLocation } from 'jotai-location'
import { atomEffect } from 'jotai-effect'
import { atomWithStorage } from 'jotai/utils'
import { equal } from '../app/api/fileService'
import {
  dataValuesObj,
  stakzFileContext,
  stakzServerHealthy as stakzServerHealthyAtom,
} from './stakzStore'

const _activeViewType = atom<'browse' | 'editor'>('browse')
const _openFiles = atomWithStorage<File[]>('openFiles', [])

const defaultGithubFile: GithubFile = {
  type: 'github',
  org: 'writeabyte',
  repo: 'stakz-example',
  path: 'index.md',
}
const defaultLocalFile: LocalFile = {
  type: 'local',
  path: 'index.md',
  saved: true,
}

export const tabValue = (f: File | undefined) => {
  if (!f) {
    return ''
  }
  if (f.type == 'github') {
    return `${f.org}/${f.repo}/${f.path}`
  } else {
    return `${f.type}/${f.path}`
  }
}

export const tabKey = (f: File | undefined) => {
  if (!f) {
    return ''
  }
  if (f.type == 'ai') {
    return tabValue(f) + f.promptId
  }
  return tabValue(f)
}

export const locationAtom = atomWithLocation()

export const fileForPath = atom<File | undefined>(get => {
  const location = get(locationAtom)
  // The underscore is because of the leading slash
  const [_, type, ...rest] = location.pathname?.split('/') || []
  if (type == 'browse') {
    const [org, repo, path] = rest
    return {
      type: 'github',
      org,
      repo,
      path,
    }
  } else if (type == 'editor') {
    const path = rest.join('/')
    if (isFilePathAI(path)) {
      const [_, modelName] = rest
      const baseAiFile = modelConfigs.find(c => c.name == modelName)
      if (!baseAiFile) {
        console.error(`Unknown Model name: ${modelName}`)
        return undefined
      }
      return {
        type: 'ai',
        model: modelName,
        uiName: baseAiFile.uiName,
        saved: false,
        path,
      }
    } else {
      return {
        type: 'local',
        saved: true,
        path,
      }
    }
  }
})

export const openFileForPath = atom(get => {
  const file = get(fileForPath)
  if (!file) {
    return undefined
  }
  get(openFiles).find(f => equal(f, file))
})

// Define a side effect atom to handle the async function
export const setFilesEffect = atomEffect((get, set) => {
  const viewType = get(_activeViewType)
  const stakzServerHealthy = get(stakzServerHealthyAtom)
  const fs = viewType === 'browse' ? githubFileService : localFileService
  const abortController = new AbortController()
  if (!stakzServerHealthy && viewType === 'editor') {
    set(currentFiles, [])
    return
  }
  ;(async () => {
    const newFiles = await fs.getFiles()
    set(currentFiles, newFiles)
    if (!_activeFileForViewType.get(viewType) && newFiles.length > 0) {
      set(currentFile, newFiles.find(f => f.path === 'index.md') || newFiles[0])
    }
  })()
  return () => abortController.abort()
})

// Define a side effect atom to handle the async function
export const addNewOpenFileEffect = atomEffect((get, set) => {
  const newFile = get(_currentFile)
  if (!newFile) {
    return
  }
  const _currentFiles = get(currentFiles)
  const existingFile = _currentFiles.find(p => equal(p, newFile))
  if (!existingFile) {
    set(currentFiles, [..._currentFiles, newFile])
  }
})

export const setCurrentFileForPathEffect = atomEffect((get, set) => {
  const file = get(openFileForPath)
  if (file) {
    set(currentFile, file)
  }
})

const setFileContentEffect = atom(null, async (_, set, file: File) => {
  if (file.content) {
    set(stakzFileContext, file.content)
  } else {
    let content = ''
    switch (file.type) {
      case 'github':
        content = await githubFileService.getFileContent(file)
        break
      case 'ai':
        content = await s3FileService.getFileContent(file)
        break
      case 'local':
        content = await localFileService.getFileContent(file)
    }

    file.content = content
    set(stakzFileContext, content)
  }
})

export const activeViewType = atom(
  get => get(_activeViewType),
  (_, set, newViewType: 'browse' | 'editor') => {
    set(_activeViewType, newViewType)
    const newFileForViewType = _activeFileForViewType.get(newViewType)
    newFileForViewType && set(currentFile, newFileForViewType)
  },
)

const _activeFileForViewType: Map<'browse' | 'editor', File | undefined> =
  new Map()
_activeFileForViewType.set('browse', defaultGithubFile)
_activeFileForViewType.set('editor', defaultLocalFile)

const _currentFile = atom<File | undefined>(defaultGithubFile)

export const currentFile = atom(
  get => {
    return get(_currentFile)
  },
  (_, set, newFile: File | undefined) => {
    set(_currentFile, newFile)
    if (newFile === undefined) {
      return
    }
    set(_activeViewType, newFile.type == 'local' ? 'editor' : 'browse')
    set(_currentFile, newFile)
    set(dataValuesObj, {})

    _activeFileForViewType.set(
      newFile.type == 'local' ? 'editor' : 'browse',
      newFile,
    )
    const newViewType = newFile.type == 'local' ? 'editor' : 'browse'
    set(_activeViewType, newViewType)
    set(setFileContentEffect, newFile)
    set(addOpenPath, newFile)
    if (newFile.type == 'local' || newFile.type == 'ai') {
      _activeFileForViewType.set('editor', newFile)
      set(locationAtom, { pathname: `/editor/${newFile.path}` })
    } else {
      _activeFileForViewType.set('browse', newFile)
      set(locationAtom, {
        pathname: `/browse/${newFile.org}/${newFile.repo}/${newFile.path}`,
      })
    }
  },
)

export const currentPath = atom(get => get(currentFile)?.path)
export const currentFiles = atom<File[]>([])
export const openFiles = atom(get => get(_openFiles))

export const addOpenPath = atom(null, (get, set, f: File) => {
  const currentOpenFiles = get(_openFiles)
  const existingFileIndex = currentOpenFiles.findIndex(p => equal(p, f))
  if (existingFileIndex > -1) {
    currentOpenFiles[existingFileIndex] = f
    set(_openFiles, currentOpenFiles)
    return
  }
  set(_openFiles, [...get(_openFiles), f])
})

export const addOpenPaths = atom(null, (get, set, f: File[]) => {
  const openFiles = get(_openFiles)
  const newFiles = f.filter(p => !openFiles.find(p2 => equal(p2, p)))
  if (newFiles.length == 0) {
    return
  }
  set(_openFiles, [...openFiles, ...newFiles])
})

export const closeFile = atom(null, (get, set, f: File) => {
  const newOpenFiles = get(_openFiles)
    .filter(p => !equal(p, f))
    .filter(
      p => p.type != 'ai' || (f.type == 'ai' ? p.promptId != f.promptId : true),
    )
  set(_openFiles, newOpenFiles)
  set(currentFile, newOpenFiles.length > 0 ? newOpenFiles[0] : undefined)
})

export const isLoadingPrompt = atom(false)
