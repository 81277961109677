export type WidgetType = '__all__' | 'code' | 'yaml' | 'json' | 'script'
// types.ts

// TODO: Figure out how fileName can be defined for ANything or AutomationTutorial but not the others
export type ChatRequest = {
  sessionKey?: string
  prompt: string
  systemPrompt: string
  widgetTypes: WidgetType[]
  type: 'Automation Tutorial' | 'Command' | 'Anything' | 'filename'
  selectedModels: string[]
  fileName?: string
}

export type CommandChatRequest = ChatRequest & {
  type: 'command'
  command: string
  stderr: string
}

export type PromptChatResponse = {
  responses: { response: string; fileName: string }[]
  sessionKey: string
}

export type CliChatResponse = {
  explanation: string
  remediation: { command: string; description: string }[]
}

export type ChatResponse = PromptChatResponse | CliChatResponse

export type ModelConfig = {
  provider: 'google' | 'openai' | 'anthropic' | 'deepseek'
  maxTokens?: number
  name: string
  uiName: string
}

export const modelConfigs: ModelConfig[] = [
  { provider: 'google', name: 'gemini-1.5-flash', uiName: 'Gemini 1.5' },
  { provider: 'openai', name: 'gpt-4', maxTokens: 8192, uiName: 'GPT-4' },
  {
    provider: 'openai',
    name: 'gpt-3.5-turbo',
    maxTokens: 4096,
    uiName: 'GPT-3.5 Turbo',
  },
  {
    provider: 'anthropic',
    name: 'claude-3-5-sonnet-20241022',
    uiName: 'Claude 3.5 Sonnet (New)',
  },
  {
    provider: 'anthropic',
    name: 'claude-3-5-haiku-20241022',
    uiName: 'Claude 3.5 Haiku',
  },
  {
    provider: 'anthropic',
    name: 'claude-3-5-sonnet-20240620',
    uiName: 'Claude 3.5 Sonnet (Old)',
  },
  {
    provider: 'anthropic',
    name: 'claude-3-haiku-20240307',
    uiName: 'Claude 3 Haiku',
  },
  // {
  //   provider: 'deepseek',
  //   name: 'deepseek-chat',
  //   uiName: 'Deepseek',
  // },
  // {
  //   provider: 'anthropic',
  //   name: 'claude-3-opus-20240229',
  //   uiName: 'Claude 3 Opus',
  // },
]
